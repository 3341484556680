import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment'

@Pipe({
  name: 'filtro'
})
export class FiltroPipe implements PipeTransform {

  transform(arreglo:Array<any>, texto: string ): any[] {
    // console.log("Arreglo",arreglo);
    // console.log("Texto", texto);

    if(texto === ''){
      return arreglo;
    }
    if( !arreglo) {
      return arreglo;
    }
    
    texto = texto.toLocaleLowerCase();

    return arreglo.filter( item => 
      // Agregamos las colunas donde querramos buscar 
      String(item?.folio).toLowerCase().includes( texto )    ||   
      item?.department?.toLowerCase().includes( texto )  ||
      item?.title?.toLowerCase().includes( texto )  ||
      item?.description?.toLowerCase().includes( texto ) ||
      item?.type?.toLowerCase().includes( texto )     || 
      item?.create_user_name?.toLowerCase().includes( texto )  ||
      item?.client?.toLowerCase().includes( texto ) || 
      item?.request_user_name?.toLowerCase().includes( texto )  ||    
      item?.service?.toLowerCase().includes( texto )   ||
      item?.contract?.toLowerCase().includes( texto )  ||
      item?.contractCode?.toLowerCase().includes( texto )  ||
      item?.contract_lu?.title?.toLowerCase().includes( texto )  ||
      item?.contract_lu?.contract_type?.toLowerCase().includes( texto )  ||
      item?.updated?.user?.name?.toLowerCase().includes( texto )  ||
      item?.departmentName?.toLowerCase().includes( texto )  ||
      item?.customerName?.toLowerCase().includes( texto ) ||
      item?.customer_lu?.name?.toLowerCase().includes( texto ) ||

      moment(item?.createBy?.date).format('ll').toLowerCase().includes( texto )  ||
      moment(item?.create_date).format('ll').toLowerCase().includes( texto ) ||
      JSON.stringify(item)?.toLocaleLowerCase().includes( texto )
    );
  }

}
