import { TitleCasePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { statusPipe } from 'src/app/shared/pipes/status.pipe';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DropdownService } from 'src/app/shared/services/dropdown.service';

@Component({
  selector: 'mkt-status-change',
  templateUrl: './mkt-status-change.component.html',
  styleUrls: ['./mkt-status-change.component.scss', '../../mk-table.comp.scss', '../../mk-table.comp.input.scss'],
  host:{
    "class" : 'mkt_col col-status',
    "(click)": '!readonly ? this.setFocus(true) : {}',
    "[class.has-change]": "_hasChange"
  }
})
export class MktStatusChangeComponent implements OnInit {
  
  public focus = false;
  public maxLength: number = 0;
  private _hasChange: boolean = false;
  @ViewChild('input', {static: false}) input: ElementRef;

  @Input() options: IOption[];
  @Input() value: string;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() readonly: boolean;
  @Input() idToValue: boolean = false;
  @Input() biddingActive: boolean = false;
  @Input() row: any = null;
  @Input() column: any = null;
  
  public setFocus = (focus: boolean) => {this.focus = focus; if(!focus) this.dropdown.closeDialog();}

  public statusColor = (name: string)=>{
    return this.options?.find(op => (op.name === name) || (op.value === name))?.color || '#c2c5cf'
  }

  constructor(
    private dropdown: DropdownService, 
    public titlecase: TitleCasePipe,
    private alertService: AlertService,
    private statusPipe: statusPipe
  ) { }

  ngOnInit(): void {
    
    // let arrayName = []
    // for (const option of this.options) {
    //   arrayName.push(this.statusPipe.transform(option.name)?.length);
    // }

    // this.options?.sort(function (a, b){
    //   const nameA = a.name.toUpperCase();
    //   const nameB = b.name.toUpperCase();
    //   if (nameA < nameB) {
    //     return -1;
    //   }
    //   if (nameA > nameB) {
    //     return 1;
    //   }
    //   return 0;
    // });

  }

  //ngOnChanges(): void{this._hasChange = true; setTimeout(() => {this._hasChange = false}, 2000);}

  public emitValue(ev: any){
    // console.log(this.options);
    // console.log(this.row);
    // console.log(this.column);
    
    let selected = this.options?.find(op => op.name == ev);
    // console.log(selected);
    
    if (selected?.options) {
      switch (selected?.options?.action) {
        case "GET":
          let colConfig = this.column?.find(item => {return item?.target == selected?.options?.target});
          let optSelected = colConfig?.options?.find(item => {return item?.name == this.row[selected?.options?.target]});
          selected.value = optSelected?.value;
          break;
      
        default:
          break;
      }
    }
    let value = this.idToValue ? (selected.value) : ev;
    if (this.value!=value) {
      this.valueChange.emit(value);
    }
  }

  SelectStatus(template: any, event: any){
    this.dropdown.openDialog(template, event, 60);
    this.dropdown.setHeight(42 + ((this.options.length * 40) > 300 ? 300 : (this.options.length * 40)));
  }

  public blockInfo(){
    if(this.biddingActive){
      this.alertService?.info('No se puede modificar el campo ya que se encuentra una licitacion activa.', '');
    } 
  }
}

interface IOption{
  name: string, 
  color: string, 
  value: string,
  options?: {
    target?: string,
    action?: string
  }
}