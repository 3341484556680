import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { HttpService } from 'src/app/shared/services/http.service';
import * as moment from 'moment-timezone';
import { datePicker } from 'src/app/shared/mk-configs/datePicker/datePickerLocale';
import { ValidatorsService } from 'src/app/shared/services/validators.service';
import { Options } from '@angular-slider/ngx-slider/options';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { CurrencyPipe } from '@angular/common';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { BuiltFormDataService } from 'src/app/shared/services/built-form-data.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'quote-confirm-modal',
  templateUrl: './quote-confirm-modal.component.html',
  styleUrls: ['./quote-confirm-modal.component.scss']
})
export class QuoteConfirmModalComponent implements OnInit {

  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() onConfirm: EventEmitter<any> = new EventEmitter<any>();
  @Input() modalData: any;

  public currencyMask = createNumberMask({
    prefix: '$ ',
    includeThousandsSeparator: true,
    allowDecimal: true,
    decimalLimit: 2,
  })

  public today = moment();
  public FormQuoteConfirme: FormGroup; 
  public isLoading: boolean = false;
  public timeZone: any = moment().tz(moment.tz.guess()).format('z'); 
  public dateOptions: any = datePicker.locale.spanish;
  public dateRanges: any = datePicker.customRange.spanish;
  public fileList: any[] = [];
  public advancePercent: any;
  public advancePercentValue: number = 0;
  
  public files:any;
  public iconList: any = [    
    { type: "pdf",  icon: "fa fa-file-pdf"   },
    { type: "jpg",  icon: "fa fa-file-image" },
    { type: "png",  icon: "fa fa-file-image" },
    { type: "jpeg", icon: "fa fa-file-image" },
    { type: "dwg",  icon: "fa fa-file" }];

  quoteItems = [
    {folio: '1.1', code: 'A023', description: 'Revisar lavado de material', unit: 'money', qty: 12, unitPrice: 632, earnMargin: 15, coverCosts: 'N/A'},
    {folio: '1.2', code: '3JBC', description: 'Agregar opción para activar alertas después de un tiempo de registrar los tickets', unit: 'time', qty: 12, unitPrice: 800, earnMargin: 0, coverCosts: 'guaranty'},
    {folio: '1.3', code: 'U812', description: 'falla general en maquinaria', unit: 'money', qty: 2, unitPrice: 550, earnMargin: 10, coverCosts: 'N/A'},
  ]
    
  public quoteTotal:number = 0;
  // public sliderOptions: Options = {
  //   floor: 0,
  //   ceil: 100,
  //   animate: false,
  //   translate: (value: number): string => {  return value + '%'; }  ,
  // };
  
  public selectDate = {startDate: moment(), endDate: moment()}; 

  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private httpService: HttpService,
    public validator: ValidatorsService,
    public validForm: ValidateFormsDirective,
    private builtservice: BuiltFormDataService,
  ) { 
    this.FormQuoteConfirme = this.fb.group({
      folio: [null, Validators.compose([Validators.required, Validators.maxLength(8)])],
      title: [null, Validators.compose([Validators.required, Validators.maxLength(60)])],
      expire: [null],
      files: [null],
      ticket: [null],
      advance: this.fb.group({
        percent: [null, Validators.compose([Validators.pattern(/^[0-9]*(\.[0-9]+)?$/) ,Validators.min(0), Validators.max(100)])],
        amount: [null]
      }),
      comment: [null, Validators.compose([Validators.maxLength(250)])]
    });
  }

  ngOnInit(): void {
    this.quoteTotal = this.modalData?.finalTotal || 0;
  }

  public advancePercentChanged(){
    if(this.advancePercent < 0){
      this.alertService.infoToast(`El porcentaje minímo de anticipo es de 0%`);
      this.advancePercent = 0;
    }else if(this.advancePercent > 100){
      this.alertService.infoToast(`El porcentaje máximo de anticipo es de 100%`);
      this.advancePercent = 100;
    }
    
    if (!(this.quoteTotal * (this.advancePercent / 100))) {
      (this.quoteTotal * (this.advancePercent / 100))!=0?this.alertService.infoToast(`El porcentaje no es valido`):null;
      // this.quoteTotal == 0?this.alertService.infoToast(`El total de la cotiacion es de 0, por lo tanto el porcentaje de anticipo es 0.`):null
      this.advancePercent = 0;
    }
    this.advancePercentValue =  this.quoteTotal * (this.advancePercent / 100);
  }

  public buildFormData(formData: FormData, data: any, parentKey?: string) {
    if (data && (typeof data === 'object' && !parentKey) && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}` : key);
      });
    } else {
      const value = data == null ? '' : ((typeof data === 'object' && !(data instanceof File))?JSON.stringify(data):data);
      formData.append(parentKey, value);
    }
  }

  public async submit(){ 
    try {

      if (this.quoteTotal == 0) {
        Swal.fire({
          title: 'Desea Continuar?',
          text: "El total de la cotizacion es de 0, por lo tanto el porcentaje de anticipo es de 0",
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar'
  
        }).then((result) => {          
          if (result.isConfirmed) {
            this.createQuotation();
          }
        });
      }else{
        this.createQuotation();
      }

    } catch (error) {
      this.isLoading = false;
      console.log(error);
      if (error.status != 403) {
       this.alertService.errorToast("No se pudo registrar la cotizacion");
      }
    }
  }

  private createQuotation():void{
    let startDate = this.selectDate.startDate.format('YYYY-MM-DD'); 
    this.FormQuoteConfirme.patchValue({
      expire: startDate,
      ticket: this.modalData?._id,
      advance: {
        percent: parseFloat(this.advancePercent),
        amount: this.advancePercentValue
      }
    })
    
    if(!this.FormQuoteConfirme.valid){
      this.FormQuoteConfirme.markAllAsTouched();
      this.alertService.infoToast("Campos incompletos");
      return;
    };
    this.isLoading = true;
    
    let formData = new FormData();
    
    this.builtservice.buildFormData(formData, this.FormQuoteConfirme.value);
    // this.buildFormData(formData, this.FormQuoteConfirme.value);

    
    this.httpService.post(`api/quote`, formData).subscribe(response=>{
      this.onConfirm.emit();
      this.close(); 
      this.isLoading = false;
      this.alertService.successToast(response.message);
    },error => {
      // this.close(true, true);
      this.isLoading = false;
      if (error.status != 403) {
        this.alertService.errorToast(error.error.message);
      }
    }); 
  }

  public getFileExtension(filename:string):string { 
    let ext = filename.split(".").pop();
    let obj = this.iconList.filter(row => {
      if (row.type === ext) {
        return true;
      }
    });
    if (obj.length > 0) {
      let icon = obj[0].icon;
      return icon;
    } else {
      return "";
    }
  }

  public selectFile(event: any):void{
    let fileMaxSize = [];
    let fileMaxLength = [];

    for (let index = 0; index <= event.target.files.length -1; index ++) {
      if (event.target.files[index].size <= 10485760 && this.fileList.length < 3) {
        this.files = event.target.files[index];
        this.fileList.push(this.files);
      }else if (this.fileList.length >= 3) {
        fileMaxLength.push(event.target.files[index].name)
      }else{
        fileMaxSize.push(event.target.files[index].name)
      }
    } 

    event.target.value = "";
    this.FormQuoteConfirme.patchValue({files: this.fileList});
    if (fileMaxLength.length) {
      this.alertService.infoToast("Los siguientes archivos no se pudieron agregar debido a que excedieron el limite maximo de archivos (3): <br>"+ fileMaxLength.toString().replace(/,/g,"<br>"), 8000);
    }
    if (fileMaxSize.length) {
      this.alertService.infoToast("Los siguientes archivos no se pudieron agregar debido a que excedieron el limite maximo de 10MB: <br>"+ fileMaxSize.toString().replace(/,/g,"<br>"), 8000);
    }
    
  }

  // public getValue():void{
  //   this.advancePercentValue =  this.quoteTotal * (this.advancePercent / 100);
  // }
  
  // Cerrar modal
  public close(cancelConfirm: boolean = false, error: boolean = false): void { this.onClose.emit({cancelConfirm: cancelConfirm, error: error}); }
}
