import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService } from 'src/app/shared/services/alert.service';
import { BuiltFormDataService } from 'src/app/shared/services/built-form-data.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { environment } from '../../../environments/environment'; 


@Component({
  selector: 'image-edit-modal',
  templateUrl: './image-edit-modal.component.html',
  styleUrls: ['./image-edit-modal.component.scss']
})
export class ImageEditModalComponent implements OnInit {

  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() modalOption;

  public Images: any[] = [];
  public files: any[] = [];
  public isLoading:boolean = false;
  public url = environment.apiUrl;

  // public imageCustomer = { index: 0, id: null, name: null, action: "" }
  constructor(
    private toast: AlertService,
    private httpService: HttpService,
    private builtservice: BuiltFormDataService
  ) { }

  ngOnInit(): void {
    if(this.modalOption.images?.length){
      this.modalOption.images.forEach(element => { this.files.push(element)});
      this.modalOption.images.forEach(element => { this.Images.push(element)});;
    }

  }

  public async submit(){    
    try {
      this.isLoading = true
      let formData = new FormData();

      for (let i = 0; i < this.files.length; i++) {
        const file = this.files[i];
        file.id ? formData.append("file", JSON.stringify(file)) : formData.append("file", file, file.name);
      }

      formData.append("id", this.modalOption.id);
      formData.append("field", "images");

      await this.httpService.put(`api/ticket/file`, formData).toPromise();
      this.toast.successToast("Imagenes guardadas correctamente");
      this.onSubmit.emit(true);
      this.onClose.emit();
    } catch (error) {
      console.log(error);
    }finally{
      this.isLoading = false;
    }
  }

  close(): void {
    this.onClose.emit();
  }

  public addFile(event){
    if (event?.target?.files[0]?.size < 6000000) {
      if (this.Images.length >= 3) {
        this.toast.infoToast("Maximo de 3 imágenes");              
      }else{
       //Tomar Archivo del input
        let file: File = event.target.files[0];      
        this.files.push(file);

        var reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (event) => {
          let obj = { index: this.Images.length + 1, id: null, name: event.target.result as string, action: "" }
          this.Images.push(obj);
        }
      }
  
    } else {
      this.toast.infoToast("El peso del archivo debe ser menor a 5MB.");      
      event.target.value = '';
    }
  }

  deleteImage(index) {
    this.Images.splice(index,1);
    this.files.splice(index,1);
    
  }


}
