import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { url } from 'inspector';
import { allowedNodeEnvironmentFlags } from 'process';
import { logging, Logs } from 'selenium-webdriver';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DropdownService } from 'src/app/shared/services/dropdown.service';
import { ContractsService } from 'src/app/shared/services/http/finances/contracts.service';
import { environment } from 'src/environments/environment'
@Component({
  selector: 'mkt-pdf',
  templateUrl: './mkt-pdf.component.html',
  styleUrls: ['./mkt-pdf.component.scss', '../../mk-table.comp.scss', '../../mk-table.comp.input.scss'],
  host:{
    "class" : 'mkt_col col-pdf',
    "[class.has-change]": "_hasChange"
  }
})
export class MktPdfComponent implements OnInit {
  @Input() value: any;
  @Input() subId: any;
  @Input() item: any;
  @Input() target: any;
  @Input() options: any;
  @Input() readonly: boolean;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();


  public document = [
    { index: 0, id: null, name: null, action: "" },
  ];
  public url: any;
  private documentSelected = [];
  // public removedDocument: string[];
  public urlApi = environment.apiUrl;
  public docName: string = "";
  public viewMode = false; // Define si el archivo se acaba de subir o no
  constructor(private dropdown: DropdownService,public alertService: AlertService, public sanitizer: DomSanitizer, public contractsService: ContractsService) { }

  ngOnInit(): void {       
  }

  public async openPdf(template: any, event: any){
    try {
    if (this.value?.id) {

      let type = (this.value?.name).split('.');
      let index = type?.length;

      if (type[index - 1] == "pdf") {// Verifica el tipo de archivo

        await this.contractsService.getBlobFile(this.value?.name, "application/pdf").then(async (resp: any) => {
         let url = URL.createObjectURL(resp);
         this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        });
       
        
      }else{

        this.url = this.urlApi + this.value?.name
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      }
   
        this.docName = this.value?.nameOriginal;
        this.viewMode = false;

    }

    this.dropdown.openDialog(template, event, 250, true, ["mktd-block", "controls", "btn", "name"]);

    } catch (error) {
      console.log(error);
      
    }
    
    
  }

  selectDocument(previous, event, remplace = false) {
    
    if (event.target.files[0]?.size < 5000000) {
      //Tomar imagen del input
      let file: File = event.target.files[0];

      // Imagen seleccionada
      this.documentSelected[previous.index] = file;      

      var reader = new FileReader();
      reader.readAsDataURL(file);

      this.docName = file.name;
      let url = URL.createObjectURL(file);
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      
      reader.onload = (event) => {
        this.document[previous.index].name = event.target.result as string;
      }
      this.value =  file.name;
      this.valueChange.emit(file);
      this.viewMode = true;

    } else {
      this.alertService.infoToast("El tamaño del archivo debe ser menor a 5MB.");
      event.target.value = '';
    }
  }

  public deleteFile(){
    this.onDelete.emit({target: this.target, id:this.subId, type: "PDF", options: this.options});
    this.dropdown.closeDialog();  
  }

  public download(){
    try {
      console.log("value",this.value);
      
      console.log("descargando");
      
      window.open(`${this.urlApi}${this.value.filePath ? this.value.filePath : this.value?.name}`, "_blank");
    } catch (error) {
      console.log(error);
      
    }
  }
}
