import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/shared/services/http.service';
import { ValueTypeService } from 'src/app/shared/services/value-type.service';
import { UserService } from 'src/app/shared/services/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'mkt-row',
  templateUrl: './mkt-row.component.html',
  styleUrls: ['./mkt-row.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    'class': 'mkt_row',
    "[class.no-drag]": 'noData',
  }
})
export class MktRowComponent implements OnInit {
  @ViewChild('modalQuoteConfirm') private modalQuoteConfirm: TemplateRef<any>;
  @ViewChild('modalAuthorization') private modalAuthorization: TemplateRef<any>;

  @Input() columns: any = [];
  @Input() subitems: any = [];
  @Input('item') r: any = [];
  @Input() noData: boolean = false;
  @Input() showCancelButton: boolean = true;
  @Input() module: string = "DEFAULT";
  @Output() onSubDetail: EventEmitter<any> = new EventEmitter<any>();

  @Input() optionsTable: boolean = true;
  
  @Input() subDetail: boolean = false;

  @Input() detail: boolean = true;
  @Output() onDetail: EventEmitter<any> = new EventEmitter<any>();

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onAddSubitem: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteSubitem: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancelItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() onOpenSubitem: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteFile: EventEmitter<any> = new EventEmitter<any>();

  // public blockRow: boolean = false;
  private changeGroup: boolean = false;
  public modalQuote: any = {
    ticket: "", 
    target: "",  
    value: "", 
    group: "", 
    row: "",
    type: "",
  }
  public finalTotalLength = 0;

  constructor(
    private valueType: ValueTypeService,
    private modalService: NgbModal,
    public User: UserService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
  }

  detailEmit(ev: any){this.onDetail.emit(ev)}

  async change(id: string, target: string, value: any, type: any, options: any, subitem: string, isSub:boolean = false, socket: boolean = false, automation?:any){

    this.changeGroup = false;
    if((type == "STATUS" || type == "STATUS-CHANGE") && ( options?.find(option =>{return option?.name == value})?.confirmQuote || options?.find(option =>{return option?.name == value})?.authorization )){
      this.changeGroup = (type == "STATUS-CHANGE");
      this.modalQuote = {
        row: id, 
        target: target, 
        valueType: this.valueType.getType(type), 
        value: value, 
        group: this.r?.group, 
        type: type,
        options: options,
        isSub: isSub,
        subitem: subitem,
        socket: socket
      }
      if (options?.find(option =>{return option?.name == value})?.confirmQuote) {
        this.openModal(this.modalQuoteConfirm, "lg");
      }else if (options?.find(option =>{return option?.name == value})?.authorization) {
        this.openModal(this.modalAuthorization, "sm");
      }
      
    }
    else if (type == "STATUS-CHANGE") {
      let blockRow = options.find(opt => {return (opt.name === value) || (opt.value === value)})?.blockRow || false;
      this.r.blockRow = blockRow;
      this.onChange.emit({id: id, target: "blockRow", value: blockRow, type: "BLOCKROW", options: options, subitem: subitem, isSub: isSub, changeGroup: false, socket: socket});

      this.changeGroup = true;
      let idNewGrooup = options.find(opt => {return (opt.name === value) || (opt.value === value)})?.value;
      this.onChange.emit({id: id, target: target, value: value, type: type, options: options, subitem: subitem, isSub: isSub, changeGroup: this.changeGroup, newGroup: idNewGrooup, socket: socket});
      
      
    }else if(type == "STATUS"){
      isSub?
      (this.r.subitems.data.find(subData => { return subData?._id == subitem}).blockRow = options.find(opt => {return opt.name === value})?.blockRow || false) :
      (this.r.blockRow = options.find(opt => {return opt.name === value})?.blockRow || false);
      this.onChange.emit({id: id, target: "blockRow", value: (options.find(opt => {return opt.name === value})?.blockRow || false), type: "BLOCKROW", options: options, subitem: subitem, isSub: isSub, changeGroup: this.changeGroup, socket: socket});
      
    }
    
    if (!this.changeGroup ) {
      this.onChange.emit({id: id, target: target, value: value, type: type, options: options, subitem: subitem, isSub: isSub, socket: socket})
    }
    
    this.changeGroup = false;
  }
  addSub(value: any){this.onAddSubitem.emit(value)}

  public deleteSub(sub, row){
    let biddingActive = this.r?.subitems?.data?.find(item => {return item?._id == sub})?.biddingStatus == "STARTED";
    if(!biddingActive){this.onDeleteSubitem.emit({sub: sub, row: row});}
    else{
      this.alertService?.info("No se puede eliminar el subitem ya que cuenta con una licitacion activa.", "");
    }
  }
  public cancelItem(row){
    this.onCancelItem.emit(row);
  }
  async openSubitems(){ this.onOpenSubitem.emit(this.r); }

  private openModal(modal, size: string = 'xxl') {
    this.modalService.open(modal, { size: size, backdrop: 'static' });
  }
  public confirmQuote(target: string, value: any, group: string, row: string):void{
    try {
      if (this.modalQuote.type == "STATUS-CHANGE") {
        let blockRow = this.modalQuote?.options.find(opt => {return opt.name === value})?.blockRow || false;
        this.r.blockRow = blockRow;
        this.onChange.emit({id: row, target: "blockRow", value: blockRow, type: "BLOCKROW", options: this.modalQuote?.options, subitem: this.modalQuote?.subitem, isSub: this.modalQuote?.isSub, changeGroup: false, socket: this.modalQuote?.socket});

        this.changeGroup = true;
        let idNewGrooup = this.modalQuote?.options.find(opt => {return opt.name === value})?.value;
        this.onChange.emit({id: row, target: target, value: value, type: this.modalQuote.type, options: this.modalQuote?.options, subitem: this.modalQuote?.subitem, isSub: this.modalQuote?.isSub, changeGroup: this.changeGroup, newGroup: idNewGrooup, socket: this.modalQuote?.socket});
      }else if(this.modalQuote.type == "STATUS"){
        
        this.modalQuote?.isSub?
        (this.r.subitems.data.find(subData => { return subData?._id == this.modalQuote?.subitem}).blockRow = this.modalQuote?.options.find(opt => {return opt.name === value})?.blockRow || false) :
        (this.r.blockRow = this.modalQuote?.options.find(opt => {return opt.name === value})?.blockRow || false);
        this.onChange.emit({id: row, target: "blockRow", value: (this.modalQuote?.options.find(opt => {return opt.name === value})?.blockRow || false), type: "BLOCKROW", options: this.modalQuote?.options, subitem: this.modalQuote?.subitem, isSub: this.modalQuote?.isSub, changeGroup: this.changeGroup, socket: this.modalQuote?.socket});
      }
    } catch (error) {
      console.log(error);
      
    }
  }
  public subDetailEmit(id:string){this.onSubDetail.emit({_id: id})}

  public validatePermissions(permissions): boolean{
    let valid: boolean = true;
    let idUser = this.User.user._id;
    let roleUser = this.User.user.roleName;
  
    
    if (permissions && (roleUser != "ADMIN" && roleUser != "SUPERVISOR")) {

        let permission =  permissions.find(item => {return item.type == 'SHOW' })

        if (permission?.filter == "EXCLUDE" && permission?.users.some(element => {return element == idUser})) {
          valid = false
          
        }else if(permission?.filter == "INCLUDE" && !permission?.users.some(element => {return element == idUser})){
          valid = false
        }
    }

    return valid;

  }

  public blockItem(c: any, sub: any):boolean {
    let block: boolean = false;
    switch (c?.configBlock?.condition) {
      case 'EQUAL':
        block = sub[c?.configBlock?.target] ==  c?.configBlock?.value;
        break;
      case 'NOEQUAL':
        block = sub[c?.configBlock?.target] !=  c?.configBlock?.value;
        break;
    
      default:
        break;
    }
    return block;
  }

  public deleteFile(parameters){
    this.onDeleteFile.emit(parameters)
  }
}
