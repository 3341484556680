<div class="center">
    <div  *ngFor="let item of document ; index as i" class="_block d-flex justify-content-center" [class.fill]="value" placement="top" [ngbTooltip]="options?.message" tooltipClass="tooltip-small">
        <ng-container *ngIf="!value">
            <label style="width: 100px;" class="cursor-pointer">
                <span class="icon"><i class="fa fa-upload"></i></span>
            
                <span>Subir <input [disabled]="readonly" accept=".jpg, .jpeg, .png, .pdf"
                    (change)="selectDocument(document[i], $event)"
                    type="file" style="display: none;"></span>
            </label>
        
    </ng-container>

    <ng-container *ngIf="value">
        <label style="width: 90px; height: 25px;" class="cursor-pointer"  (click)="openPdf(modal,$event)">
            <span class="icon"><i class="fa fa-file"></i></span>
            <span >Ver</span>
        </label>
      
    </ng-container>
    </div>
</div>


<ng-template #modal>
<div class="mkt_dropdown">
    <div class="head">Archivo
        <button class="close" type="button"><i class="mdi mdi-close"></i></button>
    </div>
    <div class="content p-0 mktd-block">
      <div class="pdf mktd-block">
        <ng-container *ngIf="value">

        <ng-container *ngIf="docName.split('.')[1]=='pdf'">
            <embed [src]="url" class="w-100 h-100 mktd-block"> <!-- todo SANITIZAR URL DE PDF -->
        </ng-container>
        <ng-container *ngIf="docName.split('.')[1]!='pdf'">
            <img [src]="url" class="w-100 h-100 mktd-block"> <!-- todo SANITIZAR URL DE PDF -->
        </ng-container>
        </ng-container>
      </div>
      <div class="card custom-card controls">
        <div class="name"><input type="text" class="mktd-block" [(ngModel)]="docName" disabled placeholder="Nombre"></div>
        <div class="d-flex">
            <div class="btn-group">
                <button class="btn btn-icon btn-danger" [disabled]="readonly" [swal]="deleteSubItemFile" (confirm)="deleteFile(modal)"><i class="fa fa-trash"></i></button>
                <button *ngIf="!viewMode" class="btn btn-icon btn-info" (click)="download()"><i class="fa fa-download"></i></button>
            </div>
        </div>
      </div>
    </div>
</div>
</ng-template>


<swal #deleteSubItemFile class="d-none" title="¿Estas seguro de eliminar el archivo?" icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>