<ng-container *ngIf="!noData else noDataContent">
    <!-- <div class="line _border">
        <div class="mkt_col controls"></div>
        <div class="mkt_col col-{{c?.type | lowercase}}" *ngFor="let c of columns.main"></div>
    </div> -->
    <div class="line _hover _border">
        <div class="mkt_col _controls __status {{r?.status | lowercase}}">
            <div class="btns">
                <div class="dot" *ngIf="r?.subitemsCount">{{r?.subitemsCount || 0}}</div>
                <button class="det" [disabled]="!detail" title="Ver Detalle" (click)="detailEmit($event)"><i class="fa fa-eye"></i></button>
                <button class="dropdown" [disabled]="!subitems.length" [class.active]="r.subitems?.active" (click)="openSubitems()"><i class="fa fa-chevron-down"></i></button>
            </div>
            <div class="folio">
                <span>#{{r.folio || "..."}}</span>
            </div>
        </div>
        <!-- <div class="mkt_col col-{{c?.type | lowercase}}" *ngFor="let c of columns">{{r[c?.target] || null}}</div> -->
        <!-- COLUMN TYPE -->
        <ng-container *ngFor="let c of columns">
           <ng-container *ngIf="validatePermissions(c?.permissions)">
                <ng-container [ngSwitch]="c.type">
                    <ng-container *ngSwitchCase="'TEXT'">
                        <ng-container *ngTemplateOutlet="itemTEXT; context:{c:c}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'LONGTEXT'">
                        <ng-container *ngTemplateOutlet="itemLONGTEXT; context:{c:c}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'NUMBER'">
                        <ng-container *ngTemplateOutlet="itemNUMBER; context:{c:c}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'USER'">
                        <ng-container *ngTemplateOutlet="itemUSER; context:{c:c}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'STATUS'">
                        <ng-container *ngTemplateOutlet="itemSTATUS; context:{c:c}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'STATUS-CHANGE'">
                        <ng-container *ngTemplateOutlet="itemSTATUS_CHANGE; context:{c:c}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'LOG'">
                        <ng-container *ngTemplateOutlet="itemLOG; context:{c:c}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'DATE'">
                        <ng-container *ngTemplateOutlet="itemDATE; context:{c:c}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'RATING'">
                        <ng-container *ngTemplateOutlet="itemRATING; context:{c:c}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'FILES'">
                        <ng-container *ngTemplateOutlet="itemFILES; context:{c:c}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'IMAGES'">
                        <ng-container *ngTemplateOutlet="itemIMAGES; context:{c:c}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'LINK'">
                        <ng-container *ngTemplateOutlet="itemLINK; context:{c:c}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'TAG'">
                        <ng-container *ngTemplateOutlet="itemTAG; context:{c:c}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'CHECK'">
                        <ng-container *ngTemplateOutlet="itemCHECK; context:{c:c}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'SELECT'">
                        <ng-container *ngTemplateOutlet="itemSELECT; context:{c:c}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'DYNAMIC-SELECT'">
                        <ng-container *ngTemplateOutlet="itemDYNAMIC_SELECT; context:{c:c}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'CURRENCY'">
                        <ng-container *ngTemplateOutlet="itemCURRENCY; context:{c:c}"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'PDF'">
                        <ng-container *ngTemplateOutlet="itemPDF; context:{c:c}"></ng-container>
                    </ng-container>
                </ng-container>
           </ng-container>
            
        </ng-container>

        <div class="_delete" *ngIf="showCancelButton && optionsTable">
            <div class="ml-auto">
                <button title="Cancelar" [swal]="(r?.biddingActive) ? biddingActiveSwal :cancelItemSwal" (confirm)='(r?.biddingActive) ? null :cancelItem(r?._id)'><i class="fa fa-times"></i></button>
            </div>
        </div>
    </div>
    <div class="line _subitems mkt_row" [class.no-data]="!r.subitems?.data?.length" *ngIf="r.subitems?.active">
            <ng-container *ngTemplateOutlet="subitemTemplate"></ng-container>
    </div>
</ng-container>

<ng-template #noDataContent>
    <div class="line _main" style="color: var(--mkt-color);"><div class="mkt_col text-center py-2">Sin registros</div></div>
</ng-template>

<swal #deleteSubItemSwal class="d-none" title="¿Estas seguro de eliminar el SubItem?" icon="question"
  [showCancelButton]="true" [focusCancel]="true"
  [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>

<swal #cancelItemSwal class="d-none" [title]="'¿Estas seguro de cancelar el registro?'" icon="question"
  [showCancelButton]="true" [focusCancel]="true"
  [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar', confirmButtonColor: '#fd3666'}">
</swal>

<swal #biddingActiveSwal class="d-none" [title]="'No se puede cancelar el registro ya que se encuentra una licitacion activa.'" icon="info"
  [showCancelButton]="false" [focusCancel]="false"
  [swalOptions]="{confirmButtonText: 'Aceptar', confirmButtonColor: '#fd3666'}">
</swal>

<ng-template #modalQuoteConfirm let-modal>
    <quote-confirm-modal [modalData]="r" (onClose)="modal.dismiss()" (onConfirm)="confirmQuote(modalQuote.target, modalQuote.value, modalQuote.group, modalQuote.row)"></quote-confirm-modal>
</ng-template>

<ng-template #modalAuthorization let-modal>
    <auth-modal (onClose)="modal.dismiss()" (onConfirm)="confirmQuote(modalQuote.target, modalQuote.value, modalQuote.group, modalQuote.row)"></auth-modal>
</ng-template>

<ng-template #subitemTemplate>
    <!-- SUBITEMS TITLES -->
    <div class="line __title" [ngStyle]="{'padding-left': subDetail ? '44px' : '4px'}">
        <div class="mkt_col _controls border-0"></div>
        <ng-container *ngFor="let c of subitems" >
            <ng-container *ngIf="validatePermissions(c?.permissions)">
                 <div class="mkt_col" class="col-{{c?.type | lowercase}}"><span><i class="fa fa-lock text-muted" *ngIf="c?.readonly"></i> {{c.name}}</span></div>
            </ng-container>
        </ng-container>
    </div>
    <!-- SUBITEMS -->
    <div class="line">
        <div class="mkt_col _controls border-right-0 __del">
        </div>
        <div class="mkt_col" style="height: fit-content;">
            <div class="card custom-card">
                <div class="mkt_row" *ngFor="let sub of r.subitems?.data;">
                    <div class="line _hover _border __status {{sub?.status | lowercase}}">
                        <div class="mkt_col _controls float border-right-0 __del">
                            <div class="btns" *ngIf="optionsTable">
                                <button class="del" title="Eliminar" [swal]="deleteSubItemSwal" *ngIf="!r?.blockRow"
                            (confirm)='deleteSub(sub?._id, r?._id)'><i class="mdi mdi-delete"></i></button>
                            </div>

                            <i class="fa fa-lock" *ngIf="r?.blockRow"></i>
                        </div>
                        <!-- <div class="mkt_col col-{{c?.type | lowercase}}" *ngFor="let c of columns"></div> -->
                        <!-- DETAIL BTN -->
                        <ng-container *ngIf="subDetail">
                            <div class="mkt_col col-detail" (click)="subDetailEmit(sub?._id)">
                                <i class="mdi mdi-eye"></i>
                            </div>
                        </ng-container>
                        <!-- COLUMN TYPE -->
                        <ng-container *ngFor="let c of subitems.length ? subitems : columns"> 
                            <ng-container *ngIf="validatePermissions(c?.permissions)">
                            <ng-container [ngSwitch]="c.type">
<!-- .Y dynamically disabled--> <mkt-text *ngSwitchCase="'TEXT'" [disabled]="c?.configBlock?.condition == 'EQUAL' && sub[c?.configBlock?.target]?.toUpperCase() ==  sub[c?.configBlock?.target2]?.toUpperCase()" [readonly]="(c?.readonly ||  sub?.blockRow || r?.blockRow)|| false" [value]="sub[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options, sub._id, true)"></mkt-text>
                                <mkt-longtext *ngSwitchCase="'LONGTEXT'" [readonly]="(c?.readonly ||  sub?.blockRow || r?.blockRow)|| false" [value]="sub[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options, sub._id, true)"></mkt-longtext>
<!-- .Y dynamically disabled--> <mkt-number *ngSwitchCase="'NUMBER'" [disabled]="c?.configBlock?.condition == 'EQUAL' && sub[c?.configBlock?.target]?.toUpperCase() ==  sub[c?.configBlock?.target2]?.toUpperCase()" [readonly]="(c?.readonly ||   sub?.blockRow || r?.blockRow)|| false" [options]="c?.options" [value]="sub[c?.target] || 0" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options, sub._id, true)"></mkt-number>
                                <mkt-bid *ngSwitchCase="'BID'" [value]="sub[c?.target] || 0" [subId]="sub?._id" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options, sub._id, true)"></mkt-bid>
                                <mkt-user *ngSwitchCase="'USER'" [readonly]="(c?.readonly ||   sub?.blockRow || r?.blockRow)|| false" [value]="sub[c?.target] || null" [options]="{isSub: true}" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options, sub._id, true)"></mkt-user>
<!-- .Y dynamically disabled--> <mkt-status *ngSwitchCase="'STATUS'" [disabled]="c?.configBlock?.condition == 'EQUAL' && sub[c?.configBlock?.target]?.toUpperCase() ==  sub[c?.configBlock?.target2]?.toUpperCase()" [readonly]="(c?.readonly ||  sub?.blockRow || r?.blockRow)" [options]="c?.options" [value]="sub[c?.target] || null" [module]="module" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options, sub._id, true, false, c?.automation)"></mkt-status>
                                <mkt-status-change *ngSwitchCase="'STATUS-CHANGE'" [readonly]="(c?.readonly ||   sub?.blockRow || r?.blockRow)|| false" [options]="c?.options" [value]="sub[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options, sub._id, true)"></mkt-status-change>
                                <mkt-log *ngSwitchCase="'LOG'" [readonly]="(c?.readonly ||   sub?.blockRow || r?.blockRow)|| false" [value]="sub[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options, sub._id, true)"></mkt-log>
                                <mkt-date *ngSwitchCase="'DATE'" [readonly]="(c?.readonly ||   sub?.blockRow || r?.blockRow)|| false" [options]="c?.options" [value]="sub[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options, sub._id, true)"></mkt-date>
                                <mkt-rating *ngSwitchCase="'RATING'" [readonly]="(c?.readonly ||   sub?.blockRow || r?.blockRow)|| false" [value]="sub[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options, sub._id, true)"></mkt-rating>
                                <mkt-files *ngSwitchCase="'FILES'" [readonly]="(c?.readonly ||   sub?.blockRow || r?.blockRow)|| false" [options]="c?.options" [isSubitem]="true" [value]="sub[c?.target] || null" [item]="sub" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options, sub._id, true)"></mkt-files>
                                <mkt-images *ngSwitchCase="'IMAGES'" [readonly]="(c?.readonly || (c?.readonlyMobile && r?.createIn == 'MOBILE') )|| false" [options]="c?.options" [value]="r[c?.target] || null" [item]="r" [target]="c?.target" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options ,null , false)"></mkt-images>
                                <mkt-link *ngSwitchCase="'LINK'" [readonly]="(c?.readonly ||   sub?.blockRow || r?.blockRow)|| false" [value]="sub[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options, sub._id, true)"></mkt-link>
                                <mkt-tag *ngSwitchCase="'TAG'" [readonly]="(c?.readonly ||   sub?.blockRow || r?.blockRow)|| false" [options]="c?.options" [value]="sub[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options, sub._id, true)"></mkt-tag>
                                <mkt-check *ngSwitchCase="'CHECK'" [readonly]="(c?.readonly ||   sub?.blockRow || r?.blockRow)|| false" [value]="sub[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options, sub._id, true)"></mkt-check>
                                <mkt-select *ngSwitchCase="'SELECT'" [readonly]="(c?.readonly ||   sub?.blockRow || r?.blockRow)|| false" [options]="c?.options" [value]="sub[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options, sub._id, true)"></mkt-select>
                                <mkt-dynamic-select *ngSwitchCase="'DYNAMIC-SELECT'" [readonly]="(c?.readonly ||   sub?.blockRow || r?.blockRow)|| false" [options]="c?.options" [value]="sub[c?.target] || null"  [valueSelect]="sub[c?.options?.secondTarget]" (valueSelectChange)="change(r?._id, $event.target, $event.value, c?.type, c?.options ,sub._id , true)"  (valueChange)="change(r?._id,c?.target, $event, (c?.options?.dinamicOptions || false)? (c?.type):'TEXT', c?.options, sub._id, true)"></mkt-dynamic-select>
                            <mkt-text *ngSwitchCase="'CURRENCY'" [readonly]="(c?.readonly ||  sub?.blockRow || r?.blockRow) || c?.configBlock?.condition == 'EQUAL' && sub[c?.configBlock?.target]?.toUpperCase() ==  sub[c?.configBlock?.target2]?.toUpperCase()" [value]="sub[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options, sub._id, true)"></mkt-text>
                                <mkt-pdf *ngSwitchCase="'PDF'" [options]="c?.options" (onDelete)="deleteFile($event)" [subId]="sub._id" [target]="c?.target" [readonly]="(c?.readonly ||  sub?.blockRow || r?.blockRow)|| false" [value]="sub[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options, sub._id, true)"></mkt-pdf>
                            </ng-container>
                        </ng-container>
                        </ng-container>
                    </div>
                </div>
                <mkt-input [isSubitem]="true" *ngIf="!r?.blockRow && optionsTable" (onChange)="addSub($event)"></mkt-input>
            </div>
        </div>
    </div>
</ng-template>

<!-- COLUMNS -->

<ng-template #itemTEXT let-c='c'>
    <mkt-text [readonly]="(c?.readonly || (c?.readonlyMobile && r?.createIn == 'MOBILE') )|| false" [value]="r[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options ,null , false)"></mkt-text>
</ng-template>
<ng-template #itemLONGTEXT let-c='c'>
    <mkt-longtext [readonly]="(c?.readonly || (c?.readonlyMobile && r?.createIn == 'MOBILE') )|| false" [value]="r[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options ,null , false)"></mkt-longtext>
</ng-template>
<ng-template #itemNUMBER let-c='c'>
    <mkt-number [readonly]="(c?.readonly || (c?.readonlyMobile && r?.createIn == 'MOBILE') )|| false" [options]="c?.options" [value]="r[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options ,null , false)"></mkt-number>
</ng-template>
<ng-template #itemUSER let-c='c'>
    <mkt-user [readonly]="(c?.readonly || (c?.readonlyMobile && r?.createIn == 'MOBILE') )|| false" [value]="r[c?.target] || null" [options]="c?.options" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options ,null , false)"></mkt-user>
</ng-template>
<ng-template #itemSTATUS let-c='c'>
    <mkt-status [readonly]="(c?.readonly || (c?.readonlyMobile && r?.createIn == 'MOBILE') || (r?.blockRow && !c?.ignoreRowBlock))|| false" [options]="c?.options" [value]="r[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options ,null , false)"></mkt-status>
</ng-template>
<ng-template #itemSTATUS_CHANGE let-c='c'>
    <mkt-status-change [idToValue]="c?.idToValue" [readonly]="(c?.readonly || (c?.readonlyMobile && r?.createIn == 'MOBILE') || (r?.blockRow && !c?.ignoreRowBlock) || r?.biddingActive)|| false" [options]="c?.options" [value]="r[c?.target] || null" [biddingActive]="r?.biddingActive" [row]="r" [column]="columns" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options ,null , false)"></mkt-status-change>
</ng-template>
<ng-template #itemLOG let-c='c'>
    <mkt-log [readonly]="(c?.readonly || (c?.readonlyMobile && r?.createIn == 'MOBILE') )|| false" [value]="r[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options ,null , false)"></mkt-log>
</ng-template>
<ng-template #itemDATE let-c='c'>
    <mkt-date [readonly]="(c?.readonly || (c?.readonlyMobile && r?.createIn == 'MOBILE') )|| false" [options]="c?.options" [value]="r[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options ,null , false)"></mkt-date>
</ng-template>
<ng-template #itemRATING let-c='c'>
    <mkt-rating [readonly]="(c?.readonly || (c?.readonlyMobile && r?.createIn == 'MOBILE') )|| false" [value]="r[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options ,null , false)"></mkt-rating>
</ng-template>
<ng-template #itemFILES let-c='c'>
    <mkt-files [readonly]="(c?.readonly || (c?.readonlyMobile && r?.createIn == 'MOBILE') )|| false" [options]="c?.options" [value]="r[c?.target] || null" [item]="r" [target]="c?.target" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options ,null , false)"></mkt-files>
</ng-template>
<ng-template #itemIMAGES let-c='c'>
    <mkt-images [readonly]="(c?.readonly || (c?.readonlyMobile && r?.createIn == 'MOBILE') )|| false" [options]="c?.options" [value]="r[c?.target] || null" [item]="r" [target]="c?.target" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options ,null , false)"></mkt-images>
</ng-template>
<ng-template #itemLINK let-c='c'>
    <mkt-link [readonly]="(c?.readonly || (c?.readonlyMobile && r?.createIn == 'MOBILE') )|| false" [value]="r[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options ,null , false)"></mkt-link>
</ng-template>
<ng-template #itemTAG let-c='c'>
    <mkt-tag [readonly]="(c?.readonly || (c?.readonlyMobile && r?.createIn == 'MOBILE') )|| false" [options]="c?.options" [value]="r[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options ,null , false)"></mkt-tag>
</ng-template>
<ng-template #itemCHECK let-c='c'>
    <mkt-check [readonly]="(c?.readonly || (c?.readonlyMobile && r?.createIn == 'MOBILE') )|| false" [value]="r[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options ,null , false)"></mkt-check>
</ng-template>
<ng-template #itemSELECT let-c='c'>
    <mkt-select [readonly]="(c?.readonly || (c?.readonlyMobile && r?.createIn == 'MOBILE') || (r?.blockRow && !c?.ignoreRowBlock) )|| false" [options]="c?.options" [value]="r[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options ,null , false)"></mkt-select>
</ng-template>
<ng-template #itemDYNAMIC_SELECT let-c='c'>
    <mkt-dynamic-select [readonly]="(c?.readonly || (c?.readonlyMobile && r?.createIn == 'MOBILE') || (r?.blockRow && !c?.ignoreRowBlock) )|| false" [options]="c?.options" [value]="r[c?.target] || null" [valueSelect]="r[c?.options?.secondTarget]" (valueSelectChange)="change(r?._id, $event.target, $event.value, c?.type, c?.options ,null , false)" (valueChange)="change(r?._id,c?.target, $event, (c?.options?.dinamicOptions || false)? (c?.type):'TEXT', c?.options ,null , false)"></mkt-dynamic-select>
</ng-template>
<ng-template #itemCURRENCY let-c='c'>
    <mkt-text [readonly]="(c?.readonly || (c?.readonlyMobile && r?.createIn == 'MOBILE') )|| false" [value]="r[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options ,null , false)"></mkt-text>
</ng-template>
<ng-template #itemPDF let-c='c'>
    <mkt-pdf [readonly]="(c?.readonly || (c?.readonlyMobile && r?.createIn == 'MOBILE') )|| false" [value]="r[c?.target] || null" (valueChange)="change(r?._id,c?.target, $event, c?.type, c?.options ,null , false)"></mkt-pdf>
</ng-template>