import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ILogin } from '../../interfaces/ilogin'
import { IUser } from '../../interfaces/iuser';
import { environment } from '../../../environments/environment';
import { CryptoSharkService } from './crypto-shark.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
/**
 * Servicio administrador de sesión de usuario
 */
export class UserService { 
  private _login: ILogin = {}
  private _user: any = {}
  public contract_group;
  public contractConfig: any = {};
  public mensaje: string = '';
  public modulesName: any = {};
  public editOriginMobile: boolean = false;
  public membershipModule: boolean = false;
  public unitsModule: boolean = false;
  public inventoryModule: boolean = false;
  public roleTypeArray: any = null;
  public contractsMetrics: boolean = false;
  public customerChat: boolean = false;
  public financesModule: boolean = false;
  public reports = [];
  public layouts = {};
  public customFilter = [];
  public showAllDefault: boolean = false;
  constructor(
    private httpService: HttpService, 
    private router: Router, 
    private cryptoService: CryptoSharkService,
  ) { }

  /**
   * Guardar los datos del usuario
   * @param login Credenciales del usuario que inició sesión
   */
  signInCurrentUser(login: ILogin) {
    // AuthConstants.login = login;
    localStorage.setItem('ldt', JSON.stringify(login));
  }

  /**
   * Obtener datos de la sesión del usuario
   */
  get login() {
    return this._login
  }

  /**
   * Obtener datos del usuario
   */
  get user() {
    return this._user
  } 
  
  /**
   * Obtener datos de sesión del usuario validUser()
   * @param returnUrl Url a la que regresará una vez que haya iniciado sesión
   */

  getCurrentUser(returnUrl: string): Promise<boolean> {
    return new Promise((resolve) => {
      this._login = JSON.parse(localStorage.getItem(`ldt`));
      // this._login = AuthConstants.login;
      if (!this._login?.isRegister) {
        this.router.navigate(['/login'], {queryParams: { returnUrl } });
        resolve(false);
      }
      environment.apiUrl = this._login.company.server; // comentar en caso de ser subdominio localTos de igual forma en authentication
      environment.socketUrl = this._login.company.server; // comentar en caso de ser subdominio localTos de igual forma en authentication
      this.httpService.get(`api/login`).subscribe(({_id, key, datakey, email, name, userName, departments, departmentId, roleType, permissions, timeAlert, socketRoom, contractConfig, modulesName, editOriginMobile, membershipModule, unitsModule, roleTypeArray, contractsMetrics, customerChat, inventoryModule, financesModule, reports, layouts, image, customFilter, showAllDefault}) => {  
     
        this._login.company.key = key; //<-- ENCRYPT REQUESTS  
        this._login.company.dk = this.cryptoService.decrypt(datakey, this._login.company.key);//<-- DECRYPT RESPONSES 
        this._user._id = this.cryptoService.decrypt(_id, this._login.company.key);
        this._user.email = this.cryptoService.decrypt(email, this._login.company.key)
        this._user.name = this.cryptoService.decrypt(name, this._login.company.key)
        this._user.userName = this.cryptoService.decrypt(userName, this._login.company.key)
        this._user.department = departments;
        this._user.departmentId = departmentId;
        this._user.roleName = roleType;
        this._user.timeAlert = timeAlert;
        this._user.permissions = permissions;
        this._user.socketRoom = socketRoom;
        this.contractConfig = contractConfig || {};
        this.modulesName = modulesName || {};
        this.editOriginMobile = editOriginMobile;
        this.membershipModule = membershipModule || false;
        this.unitsModule = unitsModule || false;
        this.inventoryModule = inventoryModule || false;
        this.financesModule = financesModule || false;        
        this.roleTypeArray = roleTypeArray;
        this.contractsMetrics = contractsMetrics || false;
        this.customerChat = !(customerChat == false);  
        this.reports = reports || [];  
        this.layouts = layouts || {};
        this.customFilter = customFilter;
        this.showAllDefault = showAllDefault || false;
        this._user.image = image;
        if (!this._user?.permissions?.filter(item => {return item.access})?.length && roleType != 'ADMIN') {
          this.router.navigate(['/pernotfound'])
        }        
        resolve(true)
      }); 
    })

    
  }  

  /**
   * Cerrar sesión
   * Borrar los datos de sesión del usuario
   */
  signOutCurrentUser() {
    //environment.apiUrl = 'http://10.10.2.34:9000/'
    localStorage.removeItem('ldt')
    // AuthConstants.login = null;
    this.router.navigate(['/login'])
  }
}
